import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-d-flex p-flex-column" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputMask = _resolveComponent("InputMask")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "p-text-bold p-mb-2"
    }, _toDisplayString(_ctx.timeLabel), 9, _hoisted_2),
    _createVNode(_component_InputMask, {
      mask: "99:99",
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      "slot-char": "hh:mm",
      onFocusout: _ctx.setMaxTime
    }, null, 8, ["modelValue", "onFocusout"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        key: error.$message
      }, [
        (_ctx.isDirty)
          ? (_openBlock(), _createElementBlock("small", {
              key: 0,
              id: _ctx.id,
              class: "p-error"
            }, _toDisplayString(error.$message), 9, _hoisted_3))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}