import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../../assets/main_logo/GV_logo_bianco_e_verde.png'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["onmouseenter", "onmouseleave"]
const _hoisted_3 = {
  class: "user_name",
  style: {"background-color":"white","width":"60px","display":"flex","font-weight":"bold","justify-content":"center","align-items":"center","height":"60px","border-radius":"50%"}
}
const _hoisted_4 = { style: {"color":"#3dae2b","font-size":"xx-large"} }
const _hoisted_5 = {
  key: 0,
  class: "drop-down-menu",
  style: {"background-color":"black","border-radius":"5%","text-align":"center","padding":"1em","width":"80px","height":"100px","right":"5px","position":"absolute","-webkit-animation":"slideInDown 0.5s","/* Safari, Chrome and Opera > 12.1 */\n\t\t\t\t\t-moz-animation":"slideInDown 0.5s","/* Firefox < 16 */\n\t\t\t\t\t-ms-animation":"slideInDown 0.5s","/* Internet Explorer */\n\t\t\t\t\t-o-animation":"slideInDown 0.5s","/* Opera < 12.1 */\n\t\t\t\t\tanimation":"slideInDown 0.5s"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.goToHomePage && $setup.goToHomePage(...args))),
      class: "govolt_logo_header",
      src: _imports_0,
      alt: "Govolt logo"
    }),
    ($setup.showUserMenu)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "avatar_wrapper",
          onmouseenter: () => $setup.changeDropDownVisibility(true),
          onmouseleave: () => $setup.changeDropDownVisibility(false)
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString($setup.initials), 1)
          ]),
          ($setup.showDropDown)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                ($setup.showProfileOption)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.goToProfile && $setup.goToProfile(...args))),
                      style: {"color":"white","cursor":"pointer"}
                    }, " Profilo "))
                  : _createCommentVNode("", true),
                _createElementVNode("p", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.signOutUser && $setup.signOutUser(...args))),
                  style: {"color":"white","cursor":"pointer"}
                }, "Logout")
              ]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}